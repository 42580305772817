import { NOT_ALLOWED_USER_VERIFICATION_STATUS } from "constant";

export const isProtected = (route) => {
  let unprotectedRoutes = [
    '/', "/connect-peers", "/learn", "/manage", "/medicines", "/medicines/category", "/medicines/topDeals", "/offers", "/medicines/category/[id]", "/medicines/[slug]", "/medicine/[...slug]", "/medicines/allMedicines", "/sign-up", "/login", "/webinar/presenter", '/company', '/about-us', '/terms-of-service', '/privacy-policy', '/cancellation-policy', '/contact-us', '/likes/[feedId]', '/followers', '/followings', '/ayurvedic-doctor', '/ayurvedic-doctor/[city]/[doctorName]',
    '/followers/[userId]', '/followings/[userId]', '/category', '/core/followers', '/core/followings', '/core/followers/[userId]', '/core/followings/[userId]', '/core/category', '/olympiad', '/olympiad/all-time-leaderboard', '/olympiad/today-leaderboard', '/olympiad/top-performers', '/reseller/[tokenCode]', '/reseller/prescription/[orderId]', '/reseller/about', '/order/[routeContext]', '/clinics', '/clinics/[...slug]', '/offers/sabse-sasta-sunday', '/clinics/[city]', '/clinics/[city]/[clinicName]', '/offers/[slug]',
    '/[mediaType]', '/[mediaType]/[blogLanguage]', '/[mediaType]/[blogLanguage]/[blogCategory]', '/[mediaType]/[blogLanguage]/[blogCategory]/[blogTitle]', '/reseller', '/myorders/[id]', '/myorders/[id]/postpaidpayment', '/events', '/events/ayuransh', '/treatments', '/treatments/pain-management', '/treatments/pain-management/[slug]', '/treatments/skin-diseases', '/treatments/skin-diseases/[slug]', '/offers/clearance-sale', '/feeds', '/quizzes', '/webinar', '/learning', '/bot', '/about-nirog-partner', '/brands/[slug]', 
    '/services', '/services/[slug]', '/treatments/lifestyle-disorders', '/treatments/lifestyle-disorders/[slug]', '/treatments/kidney-problems', '/treatments/kidney-problems/[slug]', '/treatments/female-health-issues', '/treatments/female-health-issues/[slug]',
    '/login-individual', '/login-practitioner', '/sign-up-individual', '/sign-up-practitioner', '/treatments/respiratory-problems', '/treatments/respiratory-problems/[slug]', '/treatments/liver-problems', '/treatments/liver-problems/[slug]', '/treatments/digestive-issues', 
    '/treatments/digestive-issues/[slug]', '/smartcenter', '/practitioner', '/health-conditions/[slug]', '/health-conditions', '/request-appointment', '/refunds-returns-policy','/ayurvedic-doctor/[city]', '/404','/sanchaalak/privacy-policy',
  ];

  return !unprotectedRoutes.includes(route);
}

// 6: chemist, 4: student, 2: doctor
export const isProtectedUserType = (route, userType, verifyCheckedKey) => {
  const NOT_ALLOWED_USER_TYPE = {
    6: [],
    4: [],
    2: [],
    1: [],
  }

  const REDIRECTION_PATH = {
    // '/webinar/[webinarId]': '/webinar',
  }

  const PROTECTED_WITH_USER_ACCESS = {
    '/quizzes/[quizzId]': true,
  }

  const currentUrl = NOT_ALLOWED_USER_TYPE[userType]?.find(el => el === route);
  const isIncludeInUrlList = Boolean(currentUrl);
  // const isIncludeInVerificationStatusList = NOT_ALLOWED_USER_VERIFICATION_STATUS[userType]?.includes(verifyCheckedKey);

  const custom_redirection_only_for = {
    4: {
      0: { redirection: REDIRECTION_PATH[currentUrl] },
      2: { redirection: REDIRECTION_PATH[currentUrl] },
      4: { redirection: REDIRECTION_PATH[currentUrl] },
    },
    2: {
      0: { redirection: REDIRECTION_PATH[currentUrl] },
      2: { redirection: REDIRECTION_PATH[currentUrl] },
      4: { redirection: REDIRECTION_PATH[currentUrl] },
    },
  }

  const isRedirectionBashOnUserType = isIncludeInUrlList;

  return { isRedirectionBashOnUserType: PROTECTED_WITH_USER_ACCESS[currentUrl] ? false : isRedirectionBashOnUserType, redirectionPath: custom_redirection_only_for[userType]?.[verifyCheckedKey]?.redirection || '/' };
}