import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { getCartDetails, removeFromCart, getCartListing } from './../../services/medicine.service';
import { getAuth } from './../../services/identity.service';
import * as moengage from "services/moengage.service";
import mo_engage_ecomm from "../../assets/mo_engage/e_commerce";
const appId = process.env.NEXT_PUBLIC_APP_ID;
import { getCartCount } from "../../services/medicine.service";

const cartContext = createContext()


export function useCartContext() {
    return useContext(cartContext)
}

export const CartProvider = ({ children }) => {
    const [cartDetails, setCartDetails] = useState([]);
    const [isLoadingCart, setIsLoadingCart] = useState(false)
    const [userType, setUserType] = useState(0)
    const [nirogMoneyLimit, setNirogMoneyLimit] = useState(0)
    const entity = mo_engage_ecomm.entity;
    const [cartCount, setCartCount] = useState(0);
    const auth = getAuth();
    const [showAddMoreItemsToOrder, setShowAddMoreItemsToOrder] = useState({})
    const [updateSummary, setUpdateSummary] = useState(false)
    const [packageMarginIdOfRemovedItem, setPackageMarginIdOfRemovedItem] = useState('')
    const [proceedDisable, setProceedDisable] = useState()


    const fetchCartDetail = useCallback(() => {
        setIsLoadingCart(true);
        let userID = getAuth()?.user?.id;
        let get_cart_detail = "1";
        getCartListing(userID)
            .then((res) => {   
                if (res.status) {
                    setCartDetails(res?.entity?.cartItems || []);
                    setUserType(res?.entity?.profile?.doctor_category);
                    setNirogMoneyLimit(res?.entity?.profile?.nirogMoneyLimit);
                    setCartCount(res?.entity?.cartItems?.length)
                    setShowAddMoreItemsToOrder(res?.entity?.offerItemsText)
                    setProceedDisable(res?.entity?.totalCartAmount)

                } else {
                    setCartDetails([]);
                }
                setIsLoadingCart(false);
            });
    }, [getAuth, getCartListing, setCartDetails, setUserType, setNirogMoneyLimit, setIsLoadingCart, setCartCount, setShowAddMoreItemsToOrder, setProceedDisable]);

    useEffect(() => {
        if (auth?.user?.id) {
            getCartCount(auth?.user?.id).then((response) => {
                if (response.status) {
                    setCartCount(response.entity);
                    return;
                }
            });
        }
    }, [fetchCartDetail, auth?.user?.id]);


    const removeItemFromCart = (id, medicineName, brand, size, discountPrice, quantity, package_price_id, packageMarginId) => {
        setIsLoadingCart(true)
        const body = {
            "reseller": false,
            "isDiscountItem": false,
            "cartId": [id]
        }
        removeFromCart(id, body).then(data => {
            fetchCartDetail()
            setUpdateSummary(Math.random())
            setPackageMarginIdOfRemovedItem(packageMarginId)
        })
        let attributes = {};
        attributes[entity["remove_cart_click"]["attributes_remove_cart_click"]["category_key"]] = brand;
        attributes[entity["remove_cart_click"]["attributes_remove_cart_click"]["variant_key"]] = size;
        attributes[entity["remove_cart_click"]["attributes_remove_cart_click"]["product_id_key"]] = id;
        attributes[entity["remove_cart_click"]["attributes_remove_cart_click"]["product_key"]] = medicineName;
        attributes[entity["remove_cart_click"]["attributes_remove_cart_click"]["price_key"]] = discountPrice;
        attributes[entity["remove_cart_click"]["attributes_remove_cart_click"]["quantity_key"]] = quantity;
        moengage.trackEvent(entity.remove_cart_click.event_name, attributes)
    }

    return (
        <cartContext.Provider value={{ fetchCartDetail, cartDetails, removeItemFromCart, isLoadingCart, userType, nirogMoneyLimit, cartCount, showAddMoreItemsToOrder, packageMarginIdOfRemovedItem, setPackageMarginIdOfRemovedItem, proceedDisable }}>
            {children}
        </cartContext.Provider>
    )
}

