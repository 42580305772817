import WithAuth from "@/components/Auth/WithAuth";
import { ModalProvider } from "@/components/context/ModalContext";
import { UserProvider } from "@/components/context/UserContext";
import { VerfiyDocumentsModalProvider } from "@/components/context/VerifiyDocumentsModalContext";
import { AddressProvider } from "@/components/context/addressContext.js";
import { CartProvider } from "@/components/context/cartContext";
import { ParticipateOlympiadProvider } from "@/components/context/signUpContext";
import Head from 'next/head';
import NextNprogress from "nextjs-progressbar";
import { useEffect, useState } from "react";
import { getAuth, setAuth } from 'services/identity.service';
import "../styles/globals.css";

import { OrderProvider } from "@/components/context/orderContext";
import Loading from 'components/Loader/Loading';
import dynamic from 'next/dynamic';
import { mapUriForSitemap } from "services/common.service";
import Script from "next/script";
import { getResellerConstant } from "services/reseller.service";
import { HIDE_BOT_FOR, SKIP_URL_SAVE_FOR } from "constant";
import ReactGA from "react-ga4";
const ModalForDeactivatedUser = dynamic(() => import('molecules/Modals/ModalForDeactivatedUser'), { ssr: false, loading: () => <Loading type='layer' /> });
const baseUrl = process.env.NEXT_PUBLIC_ENV === 'development' ? 'http://localhost:4200/' : process.env.NEXT_PUBLIC_PAGE_BASE
const Custom404 = dynamic(() => import('pages/404'), { ssr: true, loading: () => <Loading type='layer' /> });


function MyApp({ Component, pageProps, router }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserActive, setIsUserActive] = useState(true);
  const [botInfo, setBotInfo] = useState(false);
  console.log = function () { };

  useEffect(() => {
    let auth = getAuth();
    if (!auth?.token && router?.state?.query?.token && (router?.state?.query?.id || router?.state?.query?.userId)) {
      setAuth({
        token: router?.state?.query?.token,

        user: {
          id: Buffer.from(
            router?.state?.query?.id || router?.state?.query?.userId,
            'base64'
          ).toString('utf-8'),
        },
      });
    }
  }, []);
  useEffect(async () => {
    document.setIsUserActive = setIsUserActive;

    if ((window?.location?.pathname in HIDE_BOT_FOR) == false) {

      let result = await getResellerConstant();
      result.entity?.botInfo?.showChatBot && setBotInfo(result.entity?.botInfo);
    }
  }, []);

  // --Chat bot only for non-logged in user--
  // useEffect(() => {
  //   let auth = getAuth();
  //   if (auth && botInfo) {
  //     setBotInfo(false);
  //     initBotPress({ hideWidget: true });
  //   }
  // }, [router.asPath])

  ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);


  useEffect(() => {
    if (!isUserActive) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [isUserActive]);

  const initBotPress = ({ hideWidget = false } = {}) => {
    window.botpressWebChat.init({
      host: botInfo?.host,
      botId: botInfo?.botId,
      hideWidget,
      className: '!z-[1000]',
      extraStylesheet: baseUrl + "/css/bot-chat.css"
    });

    window.addEventListener("message", function (event) {
      if (event.data.name === "webchatReady") {
        window.botpressWebChat.sendEvent({
          type: "proactive-trigger",
          channel: "web",
          payload: { text: "AutoTrigger workflow" }
        })
      }
    });

    window.disableBot = () => initBotPress({ hideWidget: true })
  };

  return (
    <>
      <Head>
        <title>NirogStreet</title>
        <link rel='icon' href='favicon.ico' />
      </Head>

      <NextNprogress
        color='#24acda'
        startPosition={0.1}
        stopDelayMs={100}
        height={5}
      />
      {isModalOpen && <ModalForDeactivatedUser isModalOpen={isModalOpen} />}
      <WithAuth router={router}>
        <UserProvider>
          <ParticipateOlympiadProvider>
            <OrderProvider>
              <AddressProvider>
                <VerfiyDocumentsModalProvider>
                  <ModalProvider>
                    <CartProvider>
                      {pageProps.error ? (
                        <Custom404 />
                      ) :
                        <Component {...pageProps} />
                      }
                    </CartProvider>
                  </ModalProvider>
                </VerfiyDocumentsModalProvider>
              </AddressProvider>
            </OrderProvider>
          </ParticipateOlympiadProvider>
        </UserProvider>
      </WithAuth>

      {botInfo && <Script async={true}
        strategy="worker" src={`${botInfo?.host}/assets/modules/channel-web/inject.js`} onLoad={() => {
          initBotPress();
        }}
      ></Script>}
    </>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};
  const shouldHideUrl = SKIP_URL_SAVE_FOR?.some(path => ctx?.asPath.startsWith(path)) || false;

  if (ctx.res && ctx.res.statusCode < 400 && !shouldHideUrl) {
    const uri = ctx.asPath;
    mapUriForSitemap(uri);
  }

  if (Component.getInitialProps) {
    const componentProps = await Component.getInitialProps(ctx);
    pageProps = { ...pageProps, ...componentProps };
  }

  return { pageProps };
};

export default MyApp;