import * as HttpService from "./http.service";
import * as HttpClient from './http.service';
import { getAuth } from "./identity.service";
import {
    PRODUCT_LISTING_URL, PRODUCT_LISTING_BU_ID, ADD_TO_CART, CHECK_AVAILIBLITY,
    REMOVE_FROM_CART, BOOKMARK, PRODUCT_CATEGORY_URL,
    CART_DETAILS_URL, DELETE_BOOKMARK, BANNER_LIST_URL, BANNER_LIST_URL_WITH_PUB, PRODUCT_LISTING_BY_ID_NODE, GET_MEDICINE_LIST_BY_ID, PRODUCT_CATEGORY_URL_NODE,
    VIEW_CART, UPDATE_CART, GET_PRODUCT_INVENTORY, CREATE_NOTIFY_ME, NOTIFY_ME, GET_COUPON_LIST, CART_COUNT,
    PRODUCT_CATEGORY_URL_WITHOUT_AUTH, GET_DOCTORS_LIST, GET_SHORTLISTED_FEEDS_LIST, GET_FOLLOWEE_LIST_BY_FOLLOWER_ID
    , GET_SEARCHED_CLINIC, GET_SEARCHED_DOCTOR, PRODUCT_LISTING_BY_ID_HOME, TRENDING_BRANDS, PENDING_PAYMENT_REMINDER, SUGGEST_MEDICINE, OFFER_MEDICINES, DELETE_OFFER_MEDICINES, ADD_TO_CART_V1, GET_TRENDING_MEDICINES, GET_DEAL_DATA, GET_DEAL_DATA_WITH_PUB,
    GET_OFFER_CONFIG, GET_OFFER_CONFIG_WITH_PUB, GET_SIMILAR_MEDICINE, MY_MEDICINE_LIST, MARGIN_CALCULATION, ADD_TO_CART_V2, GET_MICROSITE_DATA, GET_DELIVERY_TIME, UPDATE_CART_V2, GET_PRODUCT_INVENTORYV3
} from "./url.service";

let auth = getAuth()

export const getMedicineList = (data, token) => {
    return HttpService.postWithToken(PRODUCT_LISTING_URL(), data, token);
};

export const getMedicineListById = (data, token) => {
    return HttpService.getWithToken(GET_MEDICINE_LIST_BY_ID(data?.skip, data?.limit, data?.search, data?.brand_filter, data?.category_filter, data?.is_bookmark, data?.user_type, data?.is_deal, data?.userId, data?.id, data?.banner_product_list_id, data?.banner_product_list_type, data?.is_reseller, data?.categoryLevelOneFilter || '', data?.categoryLevelThreeFilter || '', data?.doctorId, data?.isOTC), token);
}



export const getSearchedClinic = (data, token) => {
    return HttpService.getWithToken(GET_SEARCHED_CLINIC(data?.searchTrimmed, data?.offset, data?.limit), token);
}

export const getSearchedDoctor = (data, token) => {
    return HttpService.getWithToken(GET_SEARCHED_DOCTOR(data?.searchTrimmed, data?.offset, data?.limit), token);
}

export const getCategoryList = (data, token) => {
    return HttpService.postWithToken(PRODUCT_CATEGORY_URL(), data, token)
}

export const getMedicineCategoryList = (token) => {
    return HttpService.getWithToken(PRODUCT_CATEGORY_URL_NODE(), token);
}

export const getMedicineCategoryListWithoutAuth = () => {
    return HttpService.getWithToken(PRODUCT_CATEGORY_URL_WITHOUT_AUTH());
}

export const getHomePageShortListedFeeds = () => {
    return HttpService.getWithToken(GET_SHORTLISTED_FEEDS_LIST());
}

export const getFolloweeListByFollowerID = (followerID) => {
    return HttpService.getWithToken(GET_FOLLOWEE_LIST_BY_FOLLOWER_ID(followerID));
}


export const getDoctorsListHomepage = () => {
    return HttpService.getWithToken(GET_DOCTORS_LIST());
}

export const getProductById = (data, token) => {
    return HttpService.postWithToken(PRODUCT_LISTING_BU_ID(), data, token);
}

export const getProductBySlug = (data, token) => {
    return HttpService.getWithToken(PRODUCT_LISTING_BY_ID_NODE(data?.slug, data?.userID), token);
}

export const getProductBySlugHome = (data, token) => {
    return HttpService.getWithToken(PRODUCT_LISTING_BY_ID_HOME(data?.slug), token);
}

export const getCartCount = (userID) => {
    return HttpService.getWithAuth(CART_COUNT(userID));
}

export const medAddToCart = (userId, data) => {
    return HttpClient.postWithAuth(ADD_TO_CART_V2(userId), data);
}

export const checkAvailibity = (data) => {
    return HttpService.postWithAuth(CHECK_AVAILIBLITY(), data);
}

export const removeFromCart = (id, body) => {
    return HttpService.postWithAuth(REMOVE_FROM_CART(id), body);
}

export const medBookmark = (data) => {
    return HttpService.postWithAuth(BOOKMARK(), data);
}

export const removeBookMark = (userID, package_id) => {
    return HttpService.deleteWithAuth(DELETE_BOOKMARK(userID, package_id));
}

export const getCartDetails = (userID, appId, get_cart_detail) => {datadatadata
    return HttpService.getWithAuth(CART_DETAILS_URL(userID, appId, get_cart_detail));
}

export const getBannerList = async(bannerType, userId, channel, token) => {
    if (userId) {
        let response = await HttpService.getWithToken(BANNER_LIST_URL(bannerType, userId, channel), token ?? auth?.token);
        return response?.data
    }
    return HttpService.getWithOutAuth(BANNER_LIST_URL_WITH_PUB(bannerType, userId, channel));
}

export const getCartListing = (userID) => {
    return HttpService.getWithAuth(VIEW_CART(userID));
}

export const getCartListingWithToken = (userID, token) => {
    return HttpService.getWithToken(VIEW_CART(userID), token, userID);
}

export const getProductInventoryV3 = (packageId, userId, token, isReseller = false, packagingId = '', productListId, memberPatientId, doctorId = '') => {
    return HttpService.getWithToken(GET_PRODUCT_INVENTORYV3(packageId, userId, isReseller, packagingId, productListId, memberPatientId, doctorId), token);
}
export const getProductInventory = (packageId, userId, token, isReseller = false, packagingId = '', productListId, memberPatientId, doctorId) => {
    return HttpService.getWithToken(GET_PRODUCT_INVENTORY(packageId, userId, isReseller, packagingId, productListId, memberPatientId, doctorId), token);
}

export const updateCart = (data) => {
    return HttpService.putWithAuth(UPDATE_CART(), data); // test
}
export const updateCartV2 = (data) => {
    return HttpService.putWithAuth(UPDATE_CART_V2(), data);
}

export const medNotifyMe = (data, urlParam) => {
    const { userId } = urlParam
    return HttpService.postWithAuth(CREATE_NOTIFY_ME(userId), data);
}

export const notifyMeApi = (userid, body) => {
    return HttpService.postWithAuth(NOTIFY_ME(userid), body)
}
export const fetchCouponList = (data) => {
    return HttpService.getWithAuth(GET_COUPON_LIST(data?.userId, data?.platform));
}

export const trendingBrandsList = (data) => {
    return HttpService.getWithOutAuth(TRENDING_BRANDS(data?.limit, data?.skip))
}
export const fetchPendingPaymentReminder = (userId) => {
    return HttpService.getWithAuth(PENDING_PAYMENT_REMINDER(userId))
}

export const storeMedicineSuggestion = (suggestion) => {
    return HttpService.postWithAuth(SUGGEST_MEDICINE(), { suggestion })
}

export const offerMedicineList = (data, token) => {
    return HttpService.getWithToken(OFFER_MEDICINES(data?.limit, data?.skip, data?.userID), token)
}

export const removeFromOfferCart = (userID) => {
    return HttpService.deleteWithAuth(DELETE_OFFER_MEDICINES(userID));
}

export const addOfferCart = (userId, data) => {
    return HttpService.postWithAuth(ADD_TO_CART_V1(userId), data);
}

export const getDealData = async(slug, token) => {
    if (token) {
        const response = await HttpService.getWithToken(GET_DEAL_DATA(slug), token)
        return response.data
    }
    return HttpService.getWithOutAuth(GET_DEAL_DATA_WITH_PUB(slug))

}

export const getTrendingMedicines = (skip, limit, userId, personalisationType) => {
    return HttpService.getWithOutAuth(GET_TRENDING_MEDICINES(skip, limit, userId, personalisationType))
}

export const getOfferConfig = async(token) => {
    if (token) {
        let response = await HttpService.getWithToken(GET_OFFER_CONFIG(), token)
        return response.data
    }
    return HttpService.getWithOutAuth(GET_OFFER_CONFIG_WITH_PUB())
}

export const getMyMedicine = (userId, skip, limit, token) => {
    return HttpService.getWithToken(MY_MEDICINE_LIST(userId, skip, limit), token)
}

export const getSimilarMedicine = (productId, userId, skip, limit, reseller = false, doctorId = '') => {
    return HttpService.getWithAuth(GET_SIMILAR_MEDICINE(productId, userId, skip, limit, reseller, doctorId))
}

export const getSimilarMedicineIndex = (productId, userId, skip, limit, reseller = false, doctorId = '', token) => {
    return HttpService.getWithToken(GET_SIMILAR_MEDICINE(productId, userId, skip, limit, reseller, doctorId), token)
}

export const marginCalculate = (data) => {
    return HttpService.postWithAuth(MARGIN_CALCULATION(), data);
}

export const getMircoSiteData = (slug) => {
    return HttpService.getWithOutAuth(GET_MICROSITE_DATA(slug));
}

export const getDeliveryTime = (pincode, channel) => {
    return HttpService.getWithOutAuth(GET_DELIVERY_TIME(pincode, channel));
}