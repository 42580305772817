import * as HttpService from "./http.service";
import { ADD_ADDRESS_URL, ADD_PATIENT_ADDRESS_URL, DELETE_PATIENT_ADDRESS, GET_ADDRESS_URL, GET_PATIENT_ADDRESS, GET_RESELLER_ADDRESS_URL, LIST_OF_STATES, REMOVE_USER_DEFAULT_ADDRESS, UPDATE_ADDRESS_URL, VALIDATEADDRESS } from "./url.service";

export const getStatesList = (country_id) => {
    return HttpService.getWithAuth(LIST_OF_STATES(country_id));
};

export const getAddresses = (userID) => {
    return HttpService.getWithAuth(GET_ADDRESS_URL(userID))
}

export const getResellerAddresses = (userID, patientId) => {
    return HttpService.getWithAuth(GET_RESELLER_ADDRESS_URL(userID, patientId))
}

export const createAddress = (data) => {
    return HttpService.postWithAuth(ADD_ADDRESS_URL(), data);
}

export const updateAddress = (data, address_id) => {
    return HttpService.putWithAuth(UPDATE_ADDRESS_URL(address_id), data);
}

export const removeDefaultAddress = (userId, data) => {
    return HttpService.putWithAuth(REMOVE_USER_DEFAULT_ADDRESS(userId), data);
}

export const getValidateGstAddress = (gstno, userId) => {
    return HttpService.getWithAuth(VALIDATEADDRESS(gstno, userId));
}

export const getPatientAddresses = (userID, patientId) => {
    return HttpService.getWithAuth(GET_PATIENT_ADDRESS(userID, patientId))
}

export const createPatientAddress = (data, address_id, userId, patientId) => {
    return HttpService.putWithAuth(ADD_PATIENT_ADDRESS_URL(address_id), data);
}

export const deleteAddress = (address_id, userId, patientId) => {
    return HttpService.deleteWithAuth(DELETE_PATIENT_ADDRESS(address_id, userId, patientId));
}